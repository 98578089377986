// Your web app's Firebase configuration
const FirebaseConfig = {
  apiKey: "AIzaSyA7nByvEMRQkQvftujFrir6WVyd5z2AwiY",
  authDomain: "ai-bot-cv-analyzer.firebaseapp.com",
  projectId: "ai-bot-cv-analyzer",
  storageBucket: "ai-bot-cv-analyzer.appspot.com",
  messagingSenderId: "1088684978967",
  appId: "1:1088684978967:web:1b1d4b3ebb80f041e17d52",
};

export default FirebaseConfig;
