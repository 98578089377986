import React, { lazy, Suspense, memo, useEffect } from "react";
import { useSelector } from "react-redux";
import { ConfigProvider } from "antd";
import Loading from "components/shared-components/Loading";
import { lightTheme, darkTheme } from "configs/ThemeConfig";
import { resources } from "lang";
import useBodyClass from "utils/hooks/useBodyClass";
import Routes from "routes";
import { doc, getDoc, setDoc, updateDoc, collection } from "firebase/firestore";
import { db } from "auth/FirebaseAuth";
import {
  USERS_COLLECTION,
  RECORD_COLLECTION,
  RECORD_COLLECTION_FIELDS,
} from "constants/CollectionConstants";
import { AUTH_UID } from "constants/AuthConstant";

const AppLayout = lazy(() => import("./AppLayout"));
const AuthLayout = lazy(() => import("./AuthLayout"));

const Layouts = () => {
  useEffect(() => {
    const docId = localStorage.getItem(AUTH_UID);

    if (docId) {
      const docRef = doc(collection(db, USERS_COLLECTION), docId);

      const todayId = new Date().toISOString().split("T")[0];

      const recordCollectionRef = doc(
        collection(docRef, RECORD_COLLECTION),
        todayId
      );

      const updateDocument = async () => {
        try {
          const docSnap = await getDoc(recordCollectionRef);

          if (docSnap.exists()) {
            const data = docSnap.data();
            const updatedData = {
              [RECORD_COLLECTION_FIELDS.VIEWS]:
                data[RECORD_COLLECTION_FIELDS.VIEWS] + 1,
            };
            await updateDoc(recordCollectionRef, updatedData);
          } else {
            const initialData = { applications: 0, views: 1 };
            await setDoc(recordCollectionRef, initialData);
          }
        } catch (error) {
          console.error("Error updating document:", error);
        }
      };

      // Call the async function
      updateDocument();
    }
  }, []);

  const token = useSelector((state) => state.auth.token);
  const blankLayout = useSelector((state) => state.theme.blankLayout);

  const Layout = token && !blankLayout ? AppLayout : AuthLayout;

  const locale = useSelector((state) => state.theme.locale);

  const direction = useSelector((state) => state.theme.direction);

  const currentTheme = useSelector((state) => state.theme.currentTheme);

  const currentAppLocale = resources[locale];

  useBodyClass(`dir-${direction}`);

  const themeConfig =
    currentTheme === "light" ? { ...lightTheme } : { ...darkTheme };

  return (
    <ConfigProvider
      theme={themeConfig}
      direction={direction}
      locale={currentAppLocale.antd}
    >
      <Suspense fallback={<Loading cover="content" />}>
        <Layout>
          <Routes />
        </Layout>
      </Suspense>
    </ConfigProvider>
  );
};

export default memo(Layouts);
